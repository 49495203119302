
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as analytics from '../helpers/analytics'
import { ShareModule } from '../store'

@Component({
  name: 'ShareNotFound'
})
export default class extends Vue {
  shareModule = getModule(ShareModule, this.$store)

  mounted () {
    analytics.shareLinkNotFoundScreen.track(this, {
      shareLinkFrameLocation: this.shareModule.shareLinkFrameLocation || undefined,
      shareLinkInFrame: this.shareModule.shareLinkInFrame,
      shareLinkShortId: this.$params.shortId
    })
  }
}
